var login4i = function(){
	const forms = new forms4i();
	const helpers = new formHelpers4i();
	
	const init = () =>{
		const submitBtn = document.getElementById("btnSubmit");
		if(submitBtn) submitBtn.addEventListener("click", submitForm);
	};
	
	const submitForm = (e) =>{
		 // using this ID on all forms in registration
		helpers.ClearStatus();
		helpers.ShowBusyBtn(e.currentTarget);
		const registerForm = document.getElementById("registerForm");
		if(registerForm) forms.formValidation(registerForm, formSuccess, e, helpers.ClearBusyBtn);
	};
	
	const formSuccess = () => {	
		
    	};
    	
	return{
		Init: init
	};
};

(async () =>{
	const login = new login4i();
	login.Init();
})();